import React from "react"
import { graphql } from "gatsby"
import donutGif from "../images/donut.gif"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"

const Body = styled.div`
  img {
    width: 100%;
  }
`

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
  theme,
}) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div>
        <Body dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
      }
    }
  }
`

export default Template
